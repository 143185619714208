.form-control {
	&::placeholder{
		transition: opacity 0.3s ease;
		opacity: 1;
	}
	&:hover {
		box-shadow: none;
	}
	&[readonly] {
		&:hover {}
		&:focus {}
		&:active {}
	}
	&:focus {
		box-shadow: none;

		&::placeholder{
			opacity: 0;
		}
	}
	&:active {
		box-shadow: none;
	}
	&.error {
		border-color: #ff0000 !important;
		background-color: #ffdede;
	}

	input& {}
	textarea& {}
	&[contenteditable="true"],
	textarea& {
		height: 120px;
	}
	&[contenteditable="true"] {}
}
.form-group {
	// margin-bottom: 15px;
	&:disabled,
	&[disabled],
	&.disabled {
		.form-control {
			pointer-events: none;
			cursor: default;
			opacity: 0.5;
		}
	}
}
.input-wrap,
.select-wrap {
	position: relative;
}
.select-wrap {}
.input-wrap {
	& > .ico {
		// margin-bottom: auto;
		// position: absolute;
		// margin-top: auto;
		// display: block;
		// left: 10px;
		// bottom: 0;
		// top: 0;

		// & ~ .form-control {
		// 	padding-left: 60px;
		// }
	}
}
.bootstrap-select {
	&:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		
	}
	&.btn-group.open .dropdown-toggle {
		.bs-caret {}
	}
	& > .dropdown-toggle {
		&:hover {
			.bs-caret {}
		}
		&:focus {}
		&:active {}

		&.dropdown-toggle,
		&.bs-placeholder {
			&,
			&:hover,
			&:focus,
			&:active {}
		}

		span {}
	}
	.dropdown-menu {
		&.open {}
		ul.dropdown-menu {
			& > li {
				&.selected.active {
					& > a {}
				}
				& > a {}
			}
		}
	}
}
.bs-caret {
	.caret {
		&,.bootstrap-select.btn-group .dropdown-toggle & {}
	}
}
label {
	margin-bottom: 0;
	font-weight: 400;
	cursor: pointer;
}
.hidden-input {
    visibility: hidden !important;
	position: absolute !important;
	z-index: -1 !important;
    opacity: 0 !important;
    height: 0 !important;
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.error.input-wrap_error{
	color:red;
	font-size: 14px;
}