.font-face (@fontname, @fontfile, @fontstyle: normal, @fontweight: normal) {
	@font-face {
			font-family: @fontname;
			src: url('../fonts/@{fontfile}.eot');
			src: url('../fonts/@{fontfile}.eot?#iefix') format('embedded-opentype'),
					 url('../fonts/@{fontfile}.woff') format('woff'),
					 url('../fonts/@{fontfile}.ttf') format('truetype'),
					 url('../fonts/@{fontfile}.svg#@{fontname}') format('svg');
			font-weight: @fontweight;
			font-style: @fontstyle;
	}
}

//.font-face ('rouble', 'rouble/rouble');
.font-face ('effra', 'effra/effra_light', normal, 300);
.font-face ('effra', 'effra/effra_std_rg', normal, 400);
.font-face ('effra', 'effra/effra_std_bd', normal, 700);

.effra(){
	font-family: 'effra', sans-serif;
}

.rB{
	font-style: normal;
	font-weight: normal;
	display: inline-block;
	vertical-align: baseline;
	&:after{
		content: 'a';
		display: block;
		font-family: 'rouble';
	}
}