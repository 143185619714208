.c-footer{
	background-color: #003e51;
	padding-right: 40px;
	padding-left: 40px;
	@media ( max-width: @screen-sm-max) {
		padding-right: 20px;
		padding-left: 20px;
	}
}
.c-footer_wrap{
	justify-content: space-between;
	align-items: center;
	display: flex;
	height: 50px;
	color: #fff;
	p{
		margin: 0;
		&:first-child{
			padding-right: 20px;
		}
	}
}