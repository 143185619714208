.c-main {
	background-color: #ebf0f1;
	padding-bottom: 30px;
	padding-top: 30px;
	padding-right: 10px;
	padding-left: 10px;
	h2{
		margin-bottom: 50px;
		// margin-bottom: 0;
		// font-size: 32px;
		font-size: 55px;
		color: #3c4143;
		font-weight: 700;
		@media ( max-width: @screen-sm-max) {
			margin-bottom: 30px;
		}
		@media ( max-width: @screen-xx-max) {
			font-size: 26px;
		}
	}
	p{
		margin-bottom: 20px;
		font-size: 22px;
		font-weight: 300;
		// font-size: 18px;
		color: #3c4143;
		@media ( max-width: @screen-xx-max) {
			font-size: 18px;
			// font-size: 16px;
		}
	}
}
.text_block{
	padding-right: 30px;
	padding-left: 30px;
	margin-top: -10px;
	@media ( max-width: @screen-sm-max) {
		margin-top: 0;
		padding-right: 0;
		padding-left: 0;
	}
}
.btn-group.bootstrap-select{
	width: 100%!important;
	position: relative;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) .btn{
	padding: 14px 22px 14px 10px;
	font-size: 16px;
	display: flex;
	line-height: 19px;
	color: #003e51;
	border: 1px solid #003e51;
	position: relative;
	font-weight: 700;
	border-radius: 0;
	background-color: #ccd8dc;
}
.bootstrap-select.btn-group .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
}
.dropdown-menu.inner{
	color: #003e51;
	font-size: 16px;
	li a{
		padding: 10px 15px;
	}
}
.check-label{
	width: 100%;
	input{
		border-radius: 0;
		border: 1px solid #003e51;
		width: 100%;
		height: 51px;
		line-height: 50px;
		font-size: 20px;
		padding: 5px 10px;
	}
}
.form-group{
	margin-top: 20px;
	font-size: 16px;
	font-weight: 700;

}
.form_check{
	padding-right: 20px;
	@media ( max-width: @screen-sm-max) {
		padding-right: 0;
	}
	span{
		display: inline-block;
	}
}
.btn-group.bootstrap-select.min_picker{
	width: 70px!important;
	margin-left: 10px;
}
.check-wrap{
	margin-top: 5px;
}
.check-wrap__select{
	display: flex;
	align-items: center;
}
.input_required{
	position: relative;
	&:before{
		content: '*';
		position: absolute;
		top: -3px;
		right: -8px;
		color: #ed8b00;
		@media ( max-width: @screen-sm-max) {
			right: -18px;
		}
	}
}
.span_info{
	font-size: 13px;
}
.socials{
	justify-content: space-between;
	margin-bottom: 20px;
	// font-size: 25px;
	font-size: 20px;
	display: flex;
	// max-width: 500px;
	padding-right: 20px;
	@media ( max-width: @screen-sm-max) {
		padding-right: 0;
	}
	a{
		transition: all 0.3s ease;
		display: inline-block;
		color: #ed8b00;
		&:not(:last-child){
			margin-right: 10px;
		}
		&:hover{
			text-decoration: none;
			color: #003e51;
		}
	}
}
.c-main{
	.row{
		position: relative;
	}
}
.sticky_kit_bottom {
	position: absolute !important;
	bottom: 0;
	right: 0;
}