.c-header{
	background-color: #fff;
	font-weight: 700;
	position: fixed;
	margin: auto;
	z-index: 999;
	right: 0;
	left: 0;
}
.c-header__wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	// padding-right: 20px;
	// padding-left: 20px;
	height: 100px;
}
.header_link{
	// font-size: 24px;
	font-size: 38px;
	@media ( max-width: @screen-sm-max) {
		font-size: 28px;
	}
	a{
		align-items: center;
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		overflow: hidden;
		color: #003e51;
		display: flex;
		// .big_lt{
		// 	display: inline-block;
		// 	padding-right: 10px;
		// 	position: relative;
		// 	font-size: 70px;
		// 	&:before{
		// 		background-color: #003e51;
		// 		position: absolute;
		// 		display: block;
		// 		bottom: 18px;
		// 		content: '';
		// 		width: 2px;
		// 		right: 5px;
		// 		z-index: 1;
		// 		top: 18px;
		// 	}
		// }
		&:after{
			transition: width 0.3s ease;
			background-color: #ed8b00;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 2px;
			bottom: 0;
			// top: 2px;
			width: 0;
			// right: 0;
			right: 15px;
		}
		&:before{
			transition: width 0.3s ease;
			background-color: #ed8b00;
			position: absolute;
			display: block;
			margin: auto;
			content: '';
			height: 2px;
			bottom: 0;
			width: 0;
			left: 15px;
		}
		&:hover{
			text-decoration: none;
			color: #003e51;
		}
		&:active{
			outline: 2px solid #ed8b00 !important;
		}
		&:focus,&:active{
			text-decoration: none;
		}
		&:hover:after{
			width: 50%;
		}
		&:hover:before{
			width: 50%;
		}
	}
}
.header__menu_wrap{
	font-size: 24px;
}
.header__menu{
	@media ( max-width: @screen-sm-max) {
		height: calc(~'100vh - 100px');
		transform: translateX(100%);
		transition: all 0.3s ease;
		background-color: #003e51;
		overflow-y: auto;
		position: absolute;
		display: block;
		width: 100%;
		top: 100%;
		bottom: 0;
		right: 0;
		left: 0;
		.h-list_inline-block{
			border-top: 1px solid #265b6b;
			flex-direction: column;
			margin-top: 20px;
			display: flex;
			& li{
				border-bottom: 1px solid #265b6b;
				height: 72px;
				padding: 0;
			}
			& li a{
				padding: 20px 23px;
				display: block;
				height: 72px;
				color: #fff;
				&:hover{
					color: #fff;
				}
				&:after{
					display: none;
				}
			}
		}
	}
	li{
		padding: 0 23px;
		a{
			position: relative;
			color: #003e51;
			display: block;

			&:hover,
			&:focus{
				text-decoration: none;
				color: #003e51;
			}
			&:after{
				background-color: #ed8b00;
				transition: all .4s;
				position: absolute;
				display: block;
				bottom: -2px;
				width: 20px;
				height: 2px;
				content: "";
				left: 0;
			}
			&:hover:after,
			&:focus:after{
				width: 100%;
			}
		}
	}
}
.main_menu_trig{
	background-color: transparent;
	position: relative;
	height: 40px;
	border: none;
	width: 40px;
	z-index: 10;
	&:before{
		border-bottom: 3px solid #000;
		border-top: 3px solid #000;
		transition: all 0.3s ease;
		position: absolute;
		margin: auto;
		content: '';
		bottom: 20%;
		top: 20%;
		right: 0;
		left: 0;
	}
	&:after{
		border-top: 3px solid #000;
		transition: all 0.3s ease;
		position: absolute;
		margin: auto;
		content: '';
		bottom: 0;
		top: 47%;
		right: 0;
		left: 0;
	}
}
body.menu_open{
	overflow: hidden;
	.header__menu{
		transform: translateX(0);
	}
	.main_menu_trig{
		&:before{
			transform: translateY(13px) rotate(225deg);
			border-top: 3px solid #ed8b00;
			border-bottom: none;
			bottom: 77%;
			top: 16%;
		}
		&:after{
			border-top: 3px solid #ed8b00;
			transform: rotate(315deg);
			bottom: 42%;
			top: 47%;
		}
	}
	.l-page-wrapper{
		position: fixed;
		z-index: 1;
	}
}