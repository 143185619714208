.main-slider {}
.main_slider_item {
	background-image: url(../images/tmp_file/main_slider/1.jpg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	font-weight: 700;
	height: 75vh;
	color: #fff;
	background-position: 50% 50%;
	opacity: 1;
	&_item2{
		background-image: url(../images/tmp_file/main_slider/2.jpg);
	}
	&_item3{
		background-image: url(../images/tmp_file/main_slider/3.jpg);
	}
	.other_page &{
		height: 65vh;
		@media ( max-width: @screen-md-max) {
			height: 60vh;
		}
	}
}
.main_slider{
	transition: all 0.4s ease;
	&:not(.slick-initialized){
		visibility: hidden;
		max-height: 75vh;
		overflow: hidden;
		opacity: 0;
		@media ( max-width: @screen-md-max) {
			height: 70vh;
		}
	}
	.other_page &{
		height: 65vh;
		@media ( max-width: @screen-md-max) {
			height: 60vh;
		}
		&:not(.slick-initialized){
			visibility: hidden;
			overflow: hidden;
			height: 65vh;
			opacity: 0;
			@media ( max-width: @screen-md-max) {
				height: 60vh;
			}
		}
	}
}
.main_slider_container{
	position: relative;


	// position: absolute;
	// margin: auto;
	// height: 684px;
	// z-index: 2;
	// left: 0;
	// right: 0;
	// top: 0;
	// pointer-events: none;
	// .main-next,
	// .main-prev{
	// 	pointer-events: all;
	// }
}
.main_slider-wrap {
	margin-right: -100vw;
	margin-left: -100vw;
}
.main_slider {
	margin-right: auto;
	margin-left: auto;
	position: static;
	width: 100vw;
}
.main_slider_wrapper{
	margin: 120px auto;
	max-width: 640px;
	@media ( max-width: @screen-sm-max) {
		max-width: 550px;
	}
	@media ( max-width: @screen-xs-max) {
		max-width: 80%;
	}
	@media ( max-width: @screen-xx-max) {
		margin: 10px auto;
		max-width: 80%;
	}
	h2{
		margin-bottom: 30px;
		font-weight: 400;
		font-size: 55px;
		@media ( max-width: @screen-xx-max) {
			margin-bottom: 10px;
			font-size: 26px;
		}
	}
	p{
		font-weight: 400;
		font-size: 22px;
		@media ( max-width: @screen-xx-max) {
			font-size: 18px;
		}
	}
}
	
.main-next,
.main-prev{
	width: 20px;
	height: 32px;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	background-color: transparent;
	z-index: 3;

	&:hover:before{
		border-right: 6px solid #fff;
		border-bottom: 6px solid #fff;
	}
	&:before{
		transition: all 0.3s ease;
		display: block;
		position: absolute;
		content: '';
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 1;
		margin: auto;
		width: 25px;
		height: 25px;
		border-radius: 3px;
		transform: rotate(-45deg);
		border-right: 6px solid rgba(255,255,255,0.4);
		border-bottom: 6px solid rgba(255,255,255,0.4);
	}
}
.main-prev{
	transform: rotate(180deg);
	left: 30px;
}
.main-next{
	right: 30px;
}
.slick-dots{
	margin: auto;
	text-align: center;
	padding: 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	li{
		width: 9px;
		height: 9px;
		background-color: #fff;
		border-radius: 50%;
		margin: 5px;
		cursor: pointer;
		transition: all 0.3s ease;
		display: inline-block;
		opacity: 0.5;
		&.slick-active{
			transform: scale(1.3);
			opacity: 1;
		}
		button{
			display: none;
		}
	}
}