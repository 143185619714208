button{
    // button reset
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
}
.btn{
    border: 1px solid transparent;
    background-color: transparent;
    transition: all 300ms ease;
    font-size: 20px;
    display: inline-block;
    color: inherit;
    height: 51px;
    border-radius: 11px;
    font-weight: 400;
    line-height: 48px;
    padding: 0 28px;
    @media ( max-width: @screen-xs-max) {
        font-size: 16px;
    }
    &:hover{
        
        // border-color: @White40;
        // background-color: @White80;
        // color: @CapeCod;
    }
    &:focus,
    &:active{
        // border-color: @White40;
        // background-color: @Concrete;
        // color: @CapeCod;
    }
    &_main{
        border: 2px solid #003e51;
        background-color: #ed8b00;
        border-radius: 5px;
        // font-weight: 700;
        margin: 0 auto;
        display: block;
        color: #003e51;
        &:hover{
            background-color: #ed8b00+20%;
        }
    }
    // colors
    &_white{
    }
    // states
    a&{
        text-decoration: none;
    }
    &_icon{
    }
    // sizes
    &_md{
    }
    &_sm{
    }
    &_xs{
    }
}